import React, { useEffect } from 'react';

const Sokeresultat = ({error, enheter, datasett}) => {

    useEffect(() => {
    }, [error, enheter]);

    function errorMessage() {
        if (error !== '') {
            return <div className='alert alert-danger'>{error}</div>;
        }
        return '';        
    }

    function renderBRLink(orgnr) {
        return (<a href={'https://virksomhet.brreg.no/nb/oppslag/' + datasett + '/' + orgnr} className='a' target='_blank'>{orgnr}</a>);
    }

    function renderNavn(enhet) {
        let guleSiderLink = <a className='oppl' href={'https://www.gulesider.no/' + encodeURIComponent(enhet.navn) + '/bedrifter'} target='_blank'>Gule Sider</a>;
        if (enhet.url) {
            return <span><a className='a' href={enhet.url} target='_blank'>{enhet.navn}</a>{guleSiderLink}</span>;
        } else {
            return <span>{enhet.navn}{guleSiderLink}</span>;
        }
    }

    return (
        <div id="searchResult">
            <table className='table'>
                <thead>
                    <tr>
                        <th>Org.nr.</th>
                        <th>Navn</th>
                        <th>Org.form</th>
                        <th>Forretningsadresse</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {enheter.length > 0 ? enheter.map(enhet => (
                    <tr key={enhet.orgnr}>
                    <td>{renderBRLink(enhet.orgnr)}</td>
                    <td>{renderNavn(enhet)}</td>
                    <td>{enhet.orgform}</td>
                    <td>{enhet.poststed}</td>
                    </tr>
                )) : null}
                </tbody>
            </table>
            {errorMessage()}
        </div>
    );
}

export default Sokeresultat;