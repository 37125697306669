import React, {useLayoutEffect, useState, useEffect, useRef} from 'react';
import Fylker from './Fylker';
import Kommuner from './Kommuner';
import Datasett from './Datasett';
import Sokeresultat from './Sokeresultat';
import Sokenavigasjon from './Sokenavigasjon';
import './App.css';

const App = () => {
  const [valgtFylke, setValgtFylke] = useState(-1);
  const [valgtKommune, setValgtKommune] = useState('');
  const [navnOrgnr, setNavnOrgnr] = useState('');
  const [datasett, setDatasett] = useState('enheter');
  const [enheter, setEnheter] = useState([]);
  const [error, setError] = useState('');
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  function doSetValgtFylke(fylke) {
    setValgtKommune('');
    setValgtFylke(fylke);
  }

  function isOrgnr() {
    return navnOrgnr.trim().match(/^\d{3}\s*\d{3}\s*\d{3}$/);
  }

  function isNavn() {
      return !isOrgnr() && navnOrgnr.trim() !== '';
  }

  function canSearch() {
    return (valgtFylke != -1 && valgtKommune != "") || isOrgnr() || isNavn();
  }

  function fylkeButNotKommune() {
    return valgtFylke != -1 && valgtKommune == "";
  }

  function formatPoststed(enhet) {
    if (enhet.forretningsadresse === undefined) {
      return '';
    } else if (enhet.forretningsadresse.postnummer === undefined) {
      return enhet.forretningsadresse.poststed;
    }
    return enhet.forretningsadresse.postnummer + ' ' + enhet.forretningsadresse.poststed;
  }

  function search(searchPage) {
    setEnheter([]);

    if (fylkeButNotKommune()) {
      setError('Hvis du velger fylke må du også velge kommune.');
      return;
    } else if (!canSearch() && window.selectedNaeringskoder == "") {
      setError('Du må oppgi minst ett søkekriterium.');
      return;
    }

    setPage(searchPage);

    async function doSearch(filter) {
      const versionHeaderDatasetValue = datasett == "enheter" ? "enhet" : "underenhet";
      setError('');
      const settings = {
        headers: {
          'Accept': 'application/vnd.brreg.enhetsregisteret.' + versionHeaderDatasetValue + '.v2+json'
        }
      };
      const response = await fetch('https://data.brreg.no/enhetsregisteret/api/' + datasett + '/' + filter, settings);
      if (response.status !== 200) {
        setEnheter([]);
        setError('Det oppstod en feil eller fant ingen virksomheter.');
        return;
      }
      let totalPages = 0;
      let totalElements = 0;
      const enheterBody = await response.json();
      if (enheterBody._embedded !== undefined) {
        if (datasett == "enheter") {
          setEnheter(enheterBody._embedded.enheter.map(enhet => (
            {orgnr: enhet.organisasjonsnummer, 
            navn: enhet.navn,
            orgform: enhet.organisasjonsform.beskrivelse,
            poststed: formatPoststed(enhet),
            url: enhet.hjemmeside ? "http://" + enhet.hjemmeside : null}
          )));
        } else {
          setEnheter(enheterBody._embedded.underenheter.map(enhet => (
            {orgnr: enhet.organisasjonsnummer, 
            navn: enhet.navn,
            orgform: enhet.organisasjonsform.beskrivelse,
            poststed: null,
            url: null}
          )));
        }
        totalPages = enheterBody.page.totalPages;
        totalElements = enheterBody.page.totalElements;
      } else if (enheterBody.organisasjonsnummer !== undefined) {
        let enhet;
        if (datasett == "enheter") {
          enhet = {orgnr: enheterBody.organisasjonsnummer, 
            navn: enheterBody.navn,
            orgform: enheterBody.organisasjonsform.beskrivelse,
            poststed: formatPoststed(enheterBody),
            url: enheterBody.hjemmeside ? "http://" + enheterBody.hjemmeside : null};
        } else {
          enhet = {orgnr: enheterBody.organisasjonsnummer, 
            navn: enheterBody.navn,
            orgform: enheterBody.organisasjonsform.beskrivelse,
            poststed: null,
            url: null};
        }        
        totalPages = 1;
        totalElements = 1;
        let enheter = [];
        enheter.push(enhet);
        setEnheter(enheter);
      } else {
        setError('Fant ingen enheter');
      }
      setPageCount(totalPages);
      setTotalCount(totalElements);
    }
    let filter = '';
    if (isOrgnr()) {
      filter = navnOrgnr.trim();
    } else {
      filter = '?naeringskode=' + window.selectedNaeringskoder + '&kommunenummer=' + valgtKommune;
      if (navnOrgnr.trim() !== '') {
        filter += '&navn=' + encodeURIComponent(navnOrgnr.trim());
      }
      filter += '&page=' + searchPage + '&size=25';
    }
    doSearch(filter);
  }

  function handleKeyPress(e) {
      if (e.key === 'Enter') {
        search(0);
      }
  }

  function navigate(delta) {
    search(page + delta);
  }

  return (
    <div onKeyDown={handleKeyPress}>
      <div id='criteriaDiv' className='row' style={{margin: 'auto'}}>
        <div className='col-sm-4'>
            <label htmlFor='navnOrgnr'>
                <span id="labelNavn" className={isNavn() ? 'strong' : ''}>Navn</span>
                 {" eller "}
                 <span id="labelOrgnr" className={isOrgnr() ? 'strong' : ''}>org.nr.</span>
              </label>
              <input type='text' id='navnOrgnr' 
                placeholder='Navn eller organisasjonsnummer' 
                className='form-control' 
                value={navnOrgnr} 
                onChange={(e) => setNavnOrgnr(e.target.value)}/>
            
        </div>
        <div className='col-sm-3'>
          <Fylker disabled={isOrgnr()} setValgtFylke={doSetValgtFylke}/>
        </div>
        <div className='col-sm-3'>
          <Kommuner disabled={isOrgnr() || valgtFylke == -1} fylke={valgtFylke} setValgtKommune={setValgtKommune}/>
        </div>
        <div className='col-sm-2'>
          <button id='searchButton' onClick={() => search(0)} className='btn btn-primary'>
            Søk
          </button>
        </div>
      </div>
      <Datasett setDatasett={setDatasett}/>
      <div className='row' style={{margin: 'auto'}}>
        <div className='col-12'>
          <Sokeresultat 
            error={error} 
            enheter={enheter}
            datasett={datasett}/>
        </div>
      </div>
      <div className='row' style={{margin: 'auto'}}>
        <div className='col-12'>
          <Sokenavigasjon
            page={page} 
            pageCount={pageCount} 
            totalCount={totalCount}
            navigate={navigate}
            enheter={enheter}/>
        </div>
      </div>
    </div>
  );
}

export default App;