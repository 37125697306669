import React from 'react';

const Datasett = ({setDatasett}) => {

    return (
        <div id="radioDiv" className="row" style={{margin: 'auto'}}>
            <div className="col-12">
                <label><input type="radio" name="datasett" defaultChecked onClick={() => setDatasett('enheter')}/> Hovedenhet</label>
                &nbsp;
                <label><input type="radio" name="datasett" onClick={() => setDatasett('underenheter')}/> Underenhet</label>
            </div>
        </div>
    );
}

export default Datasett;